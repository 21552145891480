import React from "react";
import "./footer.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";

import VannetukkuLogo from "../../sources/vannetukkufi.gif";
import LomarengasLogo from "../../sources/lomarengas.jpg";
import MetsahallitusLogo from "../../sources/metsahallitus.jpg";

const Footer = (props) => (
    <div className="footer">
        <div className="maps">
            <iframe
                title="maps"
                src="https://maps.google.com/maps?q=kotkanselk%C3%A4&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="100%"
                height="250"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
            ></iframe>
        </div>
        <div className="flexbox">
            <div className="box">
                <h3>YHTEYSTIEDOT</h3>
                <p>
                    <a href="tel:0400246248">0400 246248</a>
                </p>
                <p>
                    <a href="mailto:topi.paananen@gmail.com">
                        TOIVO.PAANANEN@GMAIL.COM
                    </a>
                </p>
            </div>
            <div className="box">
                <h3>NAVIGOINTI</h3>
                <ul>
                    <li>
                        <Link to="/">ETUSIVU</Link>
                    </li>
                    <li>
                        <Link to="/palju">PALJU</Link>
                    </li>
                    <li>
                        <Link to="/renkaat">RENKAAT</Link>
                    </li>
                </ul>
            </div>
            <div className="box">
                <h3>YHTEISTYÖKUMPPANIT</h3>
                <div className="images">
                    <a href="https://www.lomarengas.fi/">
                        <img
                            className="lomarengas"
                            src={LomarengasLogo}
                            alt="vannetukku"
                        />
                    </a>
                    <a href="https://vannetukku.fi">
                        <img
                            style={{ width: 230 }}
                            src={VannetukkuLogo}
                            alt="vannetukku"
                        />
                    </a>
                    <a href="https://www.metsa.fi/kiinteistokehitys">
                        <img
                            style={{ width: 200 }}
                            src={MetsahallitusLogo}
                            alt="vannetukku"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;

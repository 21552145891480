import React from 'react';
import './page-home.scss';

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";


import palju from '../../sources/palju.jpg';
import tire from '../../sources/tire3.jpeg';
import { ReactComponent as Fire } from '../../sources/fire.svg';
import { ReactComponent as Car } from '../../sources/car.svg';

const PageHome = props => (
    <div className="page-home">
        <div className="container-header">
            <img className="header-image" src={require('../../sources/mountains.svg')} alt="header"></img>
            <div className="description">
                <p className="title">Kotkanselkä oy on Kinnulassa toimiva pieni monialayritys joka tarjoaa palveluitaan pohjoisen Keski-Suomen alueella. Palveluihin kuuluu mm. kaluston vuokrausta, kiinteistö huoltoa ja uutena henkilö- ja pakettiautojen rengashuoltoa, sekä rengasmyyntiä.</p>
            </div>
        </div>
            <div class="right">
                    <p className="clickable">Keskustie 66</p>
                    <p className="clickable">43900 Kinnula</p>
                    <p className="clickable">0400 246248</p>
                    <p className="clickable">toivo.paananen@gmail.com</p>
            </div>
        <div className="container">
            <h1>PALJUN VUOKRAUS</h1>
            <div className="flexbox">
                <div className="image" alt="varaus"><img className="img" src={palju} alt="palju" /></div>
                <div className="paragraph">
                    Jos olet kuuman kylvyn tarpeessa, toimitamme paljun haluttuun paikkaan. Paljun vuokraushinta alkaen 80€/vuorokausi
                </div>
            </div>
            <div className="button">
                <Link to="/palju" className="a">VUOKRAA PALJU <Fire className="fire" alt="liekki" /></Link>
            </div>
        </div>
        <div className="container">
            <h1>RENKAAT</h1>
            <div className="flexbox">
                <div className="image" alt="auto ajaa"><img className="img" src={tire} alt="palju" /></div>
                <div className="paragraph">
                    Renkaanvaihto vanhoille vanteille. Vaihdamme sinulle uudet renkaat nopeasti ja halvalla. Toimimme myös yhteistyössä vannetukun kanssa. Renkaat saapuvat meille ja tarvittaessa vaihdamme samalla uudet renkaat alle.
                </div>
            </div>
            <div className="button">
                <Link to="/renkaat" className="a">RENKAAT <Car className="car" alt="auto" /></Link>
            </div>
        </div>
        <div className="container">
            <h1>KIINTEISTÖHUOLTO</h1>
            <div className="flexbox">
                {/* <div className="image" alt="auto ajaa"><img className="img" src={tire} alt="palju" /></div> */}
                <div className="paragraph" style={{textAlign: "center"}}>
                Teemme kiinteistöhuoltoa alihankintana mm. Metsähallitukselle. Hoidamme Ahvenlammen ja Kaunislammen eräkämppiä. Huoledimme mm. näiden kohteiden kunnossapidosta. Voit vuokrata näitä kohteita Lomarenkaan kautta! 
                </div>
            </div>
            <div style={{height: 50}}></div>
            {/* <div className="button">
                <Link to="/renkaat" className="a">RENKAAT <Car className="car" alt="auto" /></Link>
            </div> */}
        </div>
    </div>
)

export default PageHome;
import React from "react";
import "./page-palju.scss";

import Palju from "../../sources/hottub.jpg";
import palju1 from "../../sources/palju.jpg";
import palju2 from "../../sources/palju2.jpg";

import { ReactComponent as Phone } from "../../sources/phone.svg";
import { ReactComponent as Mail } from "../../sources/mail.svg";

const PagePalju = (props) => (
    <div className="page-palju">
        <div className="container-header">
            <img className="header-image" src={Palju} alt="palju"></img>
            <div className="centered">
                <h1>PALJU</h1>
            </div>
        </div>
        <div className="container-main">
            <div className="container">
                <div className="paragraph">
                    <h1>PALJUN VUOKRAUS</h1>
                    <p>
                        Kun on aika pienelle hemmottelulle esim. ystävien
                        kanssa, niin mikä sen ihananpaa kuin lämmin kylpy! Kun
                        vuokraat meiltä paljun, niin toimitamme paljun paikan
                        päälle ja asennamme paikalleen. Paljun täyttö ja
                        lämmitys jää asiakaalle! Voit vuokrata paljun joko
                        päiväksi, viikonlopuksi tai vaikka viikoksi, jolloin
                        vuokra hinta on aina edullisin vuorokaudelta. Vuokra
                        hinnat ovat ma-to 80 € vrk. pe-su 100 € vrk ja
                        viikonloppu 150 €
                    </p>
                </div>
                <div className="images">
                    <img className="i" src={palju1} alt="palju" />
                    <img className="i" src={palju2} alt="palju" />
                </div>
                <div className="buttons">
                    <div className="button">
                        <a href="tel:040 0246248">
                            SOITA <Phone className="phone" alt="puhelin" />
                        </a>
                    </div>
                    <div className="button mobile">
                        <a href="mailto:topi.paananen@gmail.com">
                            VIESTI <Mail className="mail" alt="kirjekuori" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PagePalju;

import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.scss';

import Navbar from './components/Navbar/navbar';
import NavbarMobile from './components/Navbar/ToolbarMobile/navbar-mobile';
import Backdrop from './components/Backdrop/backdrop';

import PageHome from './components/PageHome/page-home';
import PagePalju from './components/PagePalju/page-palju';
import PageTires from './components/PageTires/page-tires';
import PageRealestate from './components/PageRealestate/page-realestate'

import Footer from './components/Footer/footer';
import ScrollToTop from './components/ScrollToTop/scroll-to-top';

class App extends Component {

  state = {
    mobileOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { mobileOpen: !prevState.mobileOpen }
    });
  };

  backdropClickHandler = () => {
    this.setState({ mobileOpen: false })
  }

  render() {
    let backdrop;

    if (this.state.mobileOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    };

    return (
      <Router>
        <div className="App" style={{ height: '100%' }} ref={el => (this.div = el)}>
          {/* NAVIGATION ITEMS */}
          <Navbar drawerClickHandler={this.drawerToggleClickHandler} />
          <NavbarMobile show={this.state.mobileOpen} />
          {backdrop}

          <Switch>


            {/* HOME PAGE */}
            <Route exact path="/">
              <PageHome />
              {/* Social media */}
            </Route>

            <Route path="/palju">
              <PagePalju />
            </Route>

            <Route path="/renkaat">
              <PageTires />
            </Route>
            
            <Route path="/mökki">
              <PageRealestate />
            </Route>


          </Switch>


          <main style={{ marginTop: '64px' }}></main>
          {/* FOOTER */}
          <Footer />
          <ScrollToTop/>
        </div>
      </Router>
    );
  }
}

export default App;

import React from 'react';
import './navbar.scss';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

import NavbarButton from '../Navbar/ToolbarMobile/navbar-button';
import Logo from '../../sources/logo_white.png'

const Navbar = props => (
    <header className="navbar">
        <nav className="navbar-navigation">
            <div>
                <NavbarButton click={props.drawerClickHandler} />
            </div>
            <div className="navbar-logo">
                <a href="/"> <img className="logo" src={Logo} alt="logo"></img></a>
                <h2>KOTKANSELKÄ</h2>
            </div>
            <div className="spacer"></div>
            <div className="navbar-navigation-items">
                <ul>
                    <li><Link to="/">ETUSIVU</Link></li>
                    <li><Link to="/palju">PALJU</Link></li>
                    <li><Link to="/renkaat">RENKAAT</Link></li>
                </ul>
            </div>
        </nav>
    </header>
)

export default Navbar;
import React from "react";
import "./page-tires.scss";

import Tires from "../../sources/tires.jpg";
import tire0 from "../../sources/tire.jpeg";
import tire1 from "../../sources/tire2.jpeg";
import tire2 from "../../sources/tire3.jpeg";

import { ReactComponent as Phone } from "../../sources/phone.svg";
import { ReactComponent as Mail } from "../../sources/mail.svg";

const PageTires = (props) => (
    <div className="page-tires">
        <div class="container-header">
            <img className="header-image" src={Tires} alt="renkaita"></img>
            <div className="centered">
                <h1>RENGASPALVELU</h1>
            </div>
        </div>
        <div className="container-main">
            <div className="container">
                <div className="paragraph">
                    <h1>KINNULAN RENGASPALVELU</h1>
                    <p>
                        Kinnulan rengaspalvelu myy ja vaihtaa henkilö- ja
                        pakettiautojen renkaita. Korjaamme myös puhjenneen
                        renkaansi. Toimimme lähinnä iltaisin klo 17 jälkeen,
                        sekä viikonloppuisin sovitusti, Soita tai laita viestiä
                        niin varataan sinulla aika renkaiden vaihtoon! Seuraa
                        tarjontaa
                        <a
                            style={{ color: "#f9bc60", textDecoration: "none" }}
                            href="https://www.facebook.com/Kotkanselka"
                        >
                            &nbsp;Facebookissa&nbsp;
                        </a>
                        tai{" "}
                        <a
                            style={{ color: "#f9bc60", textDecoration: "none" }}
                            href="https://www.instagram.com/kinnulan_rengaspalvelu/"
                        >
                            &nbsp;Instagramissa
                        </a>
                        ! Sieltä löydät lisää tietoa toiminnastamme sekä
                        tarjouksia, löydät meidät nimellä Kinnulan
                        rengaspalvelu.
                    </p>
                </div>
                <div className="images">
                    <img className="i" src={tire0} alt="rengas" />
                    <img className="i" src={tire1} alt="rengas" />
                    <img className="i" src={tire2} alt="rengas" />
                </div>
                <div className="buttons">
                    <div className="button">
                        <a href="tel:040 0246248">
                            SOITA <Phone className="phone" alt="puhelin" />
                        </a>
                    </div>
                    <div className="button mobile">
                        <a href="mailto:topi.paananen@gmail.com">
                            VIESTI <Mail className="phone" alt="kirjekuori" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PageTires;

import React from 'react';
import './navbar-mobile';

const NavbarButton = props => (
    <button className="toggle-button" onClick={props.click}>
        <div className="button-line"></div>
        <div className="button-line"></div>
        <div className="button-line"></div>
    </button>
);

export default NavbarButton;